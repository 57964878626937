<template>
  <div>
    <el-form ref="form" :model="form" :rules="rules" inline label-width="auto" size="small">
      <!--订单平台-->
      <el-form-item :label="$t('title.Orderplatform') + ':'" style="display: block">
        <el-radio-group v-model="form.platForm">
          <el-radio-button
            v-for="item in platformList"
            :key="item.id"
            :label="item.id"
          >
            {{ item.platformShortName }}
          </el-radio-button>
        </el-radio-group>
      </el-form-item>
      <!--平台站点-->
      <el-form-item
        :label="$t('title.Platformsite') + ':'"
        style="display: flex;flex-wrap:wrap;align-items:center;margin-bottom: 13px"
      >
        <el-radio-group
          v-model="form.siteId"
          style="display: flex;flex-wrap: wrap"
          @input="handleSiteChange"
        >
          <el-radio
            v-for="item in siteList"
            :key="item.id"
            :label="item.id"
          >
            {{ item.siteCode }}
          </el-radio>
        </el-radio-group>
      </el-form-item>
      <!--RMA类型-->
      <el-form-item :label="$t('topic.RMAType') + ':'" style="display: block">
        <el-radio-group v-model="form.returnDetailType" @input="handleQuery">
          <el-radio :label="''">
            {{ $t('title.OrderAll') }}
          </el-radio>
          <el-radio label="0">
            {{ $t('topic.Refunds') }}
          </el-radio>
        </el-radio-group>
      </el-form-item>
      <!--RMA方式-->
      <el-form-item :label="$t('topic.RMAWay') + ':'" style="display: block">
        <el-radio-group v-model="form.returnType">
          <el-radio label="0">
            {{ $t('topic.System') }}
          </el-radio>
        </el-radio-group>
      </el-form-item>
      <!--退货单号-->
      <el-form-item label-width="0">
        <el-input
          v-model.trim="form.rmaId"
          :placeholder="$t('topic.ReturnOrderNumber')"
          clearable
          @keydown.enter.native="handleQuery"
        />
      </el-form-item>
      <!--平台订单号-->
      <el-form-item label-width="0">
        <el-input
          v-model.trim="form.orderCode"
          :placeholder="$t('topic.PlatformOrderNumber')"
          clearable
          @keydown.enter.native="handleQuery"
        />
      </el-form-item>
      <!--SKU/FBM_SKU-->
      <el-form-item label-width="0">
        <el-input v-model.trim="form.sku" clearable placeholder="SKU/FBM_SKU" />
      </el-form-item>
      <!--退货仓-->
      <el-form-item v-if="isShowMoreQuery" label-width="0">
        <el-select
          v-model.trim="form.shipperAddress"
          :placeholder="$t('topic.ReturnWarehouse')"
          clearable
          filterable
        />
      </el-form-item>
      <!--人员筛选-->
      <el-form-item v-if="isShowMoreQuery" label-width="0">
        <div style="display: flex;flex-wrap: nowrap">
          <el-select
            v-model.trim="form.operatorName"
            clearable
            filterable
            style="min-width: 100px;width: 100px;margin-right: 5px"
          >
            <el-option :label="$t('page.createByName')" value="0" />
            <el-option :label="$t('topic.Reviewer')" value="1" />
            <el-option :label="$t('topic.Moneymaker')" value="2" />
          </el-select>
          <el-input v-model="form.createByName" :placeholder="$t('page.inputPlaceholder')" clearable />
        </div>
      </el-form-item>
      <!--跟踪号-->
      <el-form-item v-if="isShowMoreQuery" label-width="0">
        <el-input v-model.trim="form.trackingId" :placeholder="$t('title.Trackingforreturn')" clearable />
      </el-form-item>
      <!--时间-->
      <el-form-item v-if="isShowMoreQuery" label-width="0">
        <div style="display: flex;flex-wrap: nowrap">
          <el-select
            v-model="form.operatorType"
            clearable
            filterable
            style="min-width: 100px;width: 100px;margin-right: 5px"
          >
            <el-option :label="$t('page.createTime')" value="0" />
            <el-option :label="$t('topic.Reviewtime')" value="1" />
            <el-option :label="$t('topic.Paymenttime')" value="2" />
          </el-select>
          <el-date-picker
            v-model="form.date"
            :end-placeholder="$t('title.endtime')"
            :start-placeholder="$t('title.starttime')"
            range-separator="~"
            type="datetimerange"
            value-format="yyyy-MM-dd HH:mm:ss"
          />
        </div>
      </el-form-item>
      <el-form-item label-width="0">
        <div style="display:flex; flex-wrap: nowrap">
          <el-button :loading="queryLoading" type="primary" @click="handleQuery">{{ $t('page.search') }}</el-button>
          <el-button @click="handleRest">{{ $t('page.reset') }}</el-button>
          <el-button type="text" @click="isShowMoreQuery = !isShowMoreQuery">
            {{ isShowMoreQuery ? $t('page.hide') : $t('page.expand') }}
          </el-button>
        </div>
      </el-form-item>
    </el-form>
    <vxe-toolbar>
      <template #buttons>
        <!--导出-->
        <el-button :loading="exportLoading" size="small" @click="handleExport">
          {{ $t('page.export') }}
        </el-button>
      </template>
    </vxe-toolbar>
    <el-scrollbar ref="returnStatusScrollbar" @wheel.stop.prevent.native="handleScroll">
      <el-radio-group
        v-model="form.returnStatus"
        style="margin-bottom: 10px;white-space: nowrap;width: 100%"
        @input="$nextTick(() => handleQuery())"
      >
        <el-radio-button v-for="item in returnStatusList" :key="item.returnStatus" :label="item.returnStatus">
          <span>{{ item.returnStatusName }}</span>
          <span v-if="item.returnStatus !== ''" style="color: red">({{ item.statusCount }})</span>
        </el-radio-button>
      </el-radio-group>
    </el-scrollbar>
    <vxe-table
      ref="table"
      :data="tableData"
      :loading="queryLoading"
      :max-height="maxHeight"
      :row-config="{ height: 110 }"
      :scroll-x="{ enabled: false }"
      :scroll-y="{ enabled: false }"
      :show-overflow="false"
      align="center"
      resizable
      size="small"
    >
      <vxe-column fixed="left" type="expand" width="30">
        <template #content="{row: parentRow}">
          <vxe-table
            :data="parentRow.detailList"
            :scroll-x="{ enabled: false }"
            :scroll-y="{ enabled: false }"
            :show-overflow="false"
            align="center"
            max-height="400"
            resizable
          >
            <!--产品名称-->
            <vxe-column :title="$t('topic.productname')" field="sku" />
            <!--数量-->
            <vxe-column :title="$t('title.Quantity')" field="originalItemQuantity" />
            <!--退款数量-->
            <vxe-column :title="$t('topic.Numberofrefunds')" field="returnNumber" />
            <!--退款原因-->
            <vxe-column :title="$t('topic.reasonforreturn')" field="returnReasonCode" />
            <!--商品金额-->
            <vxe-column :title="$t('title.Product') + $t('title.Amount')" field="itemPrice" />
            <!--退款金额-->
            <vxe-column :title="$t('topic.Refundamount')" field="refundAmount" />
            <!--买家留言-->
            <vxe-column :title="$t('title.Buyermessage')" align="left" field="sellComments" header-align="center" />
            <!--跟踪号-->
            <vxe-column :title="$t('title.Trackingforreturn')">
              <template #default="{row}">
                <el-link
                  :href="'https://t.17track.net/zh-cn#nums='+row.trackingId"
                  :underline="false"
                  target="_blank"
                  type="primary"
                >
                  {{ row.trackingId }}
                </el-link>
              </template>
            </vxe-column>
            <!--收货状态-->
            <vxe-column :title="$t('topic.Receiptstatus')" field="statusName" />
            <!--操作-->
            <vxe-column :title="$t('page.operate')" width="120">
              <template #default="{row}">
                <!--父级状态 = 2(待收货) && 收货状态 = 0(未收货)时可以确定收货-->
                <el-button
                  v-if="+parentRow.returnStatus === 2 && +row.status === 0"
                  type="text"
                  @click="handleConfirmReceipt(row, 'returnDetailId')"
                >
                  {{ $t('topic.Confirmreceipt') }}
                </el-button>
              </template>
            </vxe-column>
          </vxe-table>
        </template>
      </vxe-column>
      <vxe-column :title="$t('page.No')" fixed="left" type="seq" width="60" />
      <!--退货单号-->
      <vxe-column :title="$t('topic.ReturnOrderNumber')" fixed="left" width="120">
        <template #default="{row}">
          <el-link
            :href="'/rma-manage/shein-return-manage/shein-return-info' + '?id=' + row.id"
            :underline="false"
            target="_blank"
            type="primary"
          >
            {{ row.rmaId }}
          </el-link>
        </template>
      </vxe-column>
      <!--平台订单号-->
      <vxe-column :title="$t('topic.PlatformOrderNumber')" field="orderCode" fixed="left" width="120" />
      <!--对象-->
      <vxe-column :title="$t('title.Moreinformation')" width="160">
        <template #default="{row}">
          <el-descriptions :column="1" :content-style="{'white-space': 'nowrap'}" :label-style="{'white-space': 'nowrap'}">
            <el-descriptions-item :label="$t('title.platform')">
              {{ row.platFormName }}
            </el-descriptions-item>
            <el-descriptions-item :label="$t('title.shopName')">
              {{ row.shopCode }}
            </el-descriptions-item>
            <el-descriptions-item :label="$t('topic.Refundmethod')">
              {{ row.returnTypeName }}
            </el-descriptions-item>
          </el-descriptions>
        </template>
      </vxe-column>
      <!--RMA类型-->
      <vxe-column :title="$t('topic.RMAType')" field="returnDetailTypeName" width="80" />
      <!--状态-->
      <vxe-column :title="$t('page.status')" field="returnStatusName" width="100" />
      <!--时间-->
      <vxe-column :title="$t('title.time')" width="200">
        <template #default="{row}">
          <el-descriptions :column="1">
            <!--生成时间-->
            <el-descriptions-item :label="$t('topic.generate')">
              {{ row.returnCreateTime }}
            </el-descriptions-item>
            <!--退款时间-->
            <el-descriptions-item :label="$t('topic.refunds')">
              {{ row.returnPaymentTime }}
            </el-descriptions-item>
            <!--审核时间-->
            <el-descriptions-item :label="$t('topic.review')">
              {{ row.returnReviewTime }}
            </el-descriptions-item>
            <!--签收时间-->
            <el-descriptions-item :label="$t('topic.Submissiontime')">
              {{ row.signTime }}
            </el-descriptions-item>
          </el-descriptions>
        </template>
      </vxe-column>
      <!--备注-->
      <vxe-column :title="$t('title.note')" align="left" field="remark" header-align="center" min-width="100" />
      <!--操作人-->
      <vxe-column :title="$t('title.operator')" width="120">
        <template #default="{row}">
          <el-descriptions :column="1">
            <!--创建-->
            <el-descriptions-item :label="$t('title.create')">
              {{ row.returnCreateName }}
            </el-descriptions-item>
            <!--审核-->
            <el-descriptions-item :label="$t('topic.review')">
              {{ row.returnReviewName }}
            </el-descriptions-item>
            <!--退款-->
            <el-descriptions-item :label="$t('topic.refunds')">
              {{ row.returnPaymentName }}
            </el-descriptions-item>
          </el-descriptions>
        </template>
      </vxe-column>
      <vxe-column :title="$t('page.operate')" width="120">
        <template #default="{row}">
          <!--退货状态 = 2(待收货)时可以确定收货-->
          <el-button
            v-if="+row.returnStatus === 2"
            type="text"
            @click="handleConfirmReceipt(row, 'returnId')"
          >
            {{ $t('topic.Confirmreceipt') }}
          </el-button>
        </template>
      </vxe-column>
    </vxe-table>
    <paging ref="pager" :pager="pager" end @update="handlePagerUpdate" />
  </div>
</template>

<script>
import Paging from '@/components/Paging/index.vue'
import { debounceGetTableMaxHeight } from '@/utils'
import { getAllStatusCounts, getReturnsInfos, querySiteList, queryTerraceList } from '@/api/omsorder'
import { confirmReceipt } from '@/views/rma-manage/shein-return-manage/apis/list'
import qs from 'qs'

export default {
  name: 'SheInReturnManage',
  components: { Paging },
  data() {
    return {
      platformList: [],
      siteList: [],
      statusQueryParams: {
        siteCode: ''
      },
      form: {
        platForm: '',
        siteId: '',
        returnDetailType: '',
        returnType: '0',
        rmaId: '',
        orderCode: '',
        sku: '',
        shipperAddress: '',
        operatorName: '0',
        createByName: '',
        trackingId: '',
        operatorType: '0',
        returnStatus: 3,
        date: []
      },
      rules: {},
      isShowMoreQuery: false,
      exportLoading: false,
      returnStatusList: [],
      tableData: [],
      queryLoading: false,
      maxHeight: 0,
      pager: {
        size: 10,
        current: 1,
        total: 0
      }
    }
  },
  computed: {
    isTimeout() {
      return this.form.status === '2'
    },
    isReceived() {
      return this.form.status === '3'
    },
    siteParams() {
      return {
        sellPlatformId: this.form.platForm
      }
    },
    queryParams() {
      return {
        ...this.statusParams,
        current: this.pager.current,
        size: this.pager.size
      }
    },
    statusParams() {
      return {
        ...this.commonParams,
        ...this.statusQueryParams
      }
    },
    commonParams() {
      const [operatorStartTime, operatorEndTime] = this.form.date || []
      return {
        ...this.form,
        date: undefined,
        operatorStartTime,
        operatorEndTime
      }
    }
  },
  watch: {
    siteParams: {
      handler() {
        this.getSiteList()
      },
      deep: true
    },
    form: {
      handler() {
        this.queryChange = true
      },
      deep: true
    },
    statusParams: {
      handler() {
        this.getStatusList()
      },
      deep: true
    }
  },
  created() {
    this.getPlatformList()
    this.debounceGetTableMaxHeight = debounceGetTableMaxHeight.bind(this)
  },
  mounted() {
    this.debounceGetTableMaxHeight()
    window.addEventListener('resize', this.debounceGetTableMaxHeight)
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.debounceGetTableMaxHeight)
  },
  methods: {
    async getPlatformList() {
      const { datas } = await queryTerraceList()
      const sheIn = datas?.find(e => e.platformShortName === 'Shein')
      if (sheIn) {
        this.platformList = [sheIn]
        this.form.platForm = sheIn.id
      }
    },
    async getSiteList() {
      const { datas } = await querySiteList(this.siteParams)
      this.siteList = datas?.filter(e => e.siteCode === 'US' || e.siteCode === 'DE')
      this.form.siteId = this.siteList[0]?.id
      this.statusQueryParams.siteCode = this.siteList[0]?.siteCode
      await this.handleQuery()
    },
    async getStatusList() {
      if (!this.statusParams.platForm || !this.statusParams.siteCode) {
        return
      }
      const { datas } = await getAllStatusCounts(this.statusParams)
      this.returnStatusList = [{ 'returnStatus': '', 'statusCount': 0, 'returnStatusName': '全部' }].concat((datas || []))
    },
    handleSiteChange(val) {
      const site = this.siteList.find(e => e.id === val)
      this.statusQueryParams.siteCode = site?.siteCode
      this.handleQuery()
    },
    async handleQuery() {
      this.queryLoading = true
      if (this.controller) {
        this.controller.abort()
        setTimeout(() => {
          this.queryLoading = true
        })
      }
      this.controller = new AbortController()
      const signal = this.controller.signal
      if (this.queryChange) {
        this.pager.current = 1
      }
      const { datas: { pager, records }} = await getReturnsInfos(this.queryParams, signal).finally(() => {
        this.queryLoading = false
        this.controller = null
        this.queryChange = false
      })
      this.tableData = records || []
      this.pager.total = pager?.total || 0
    },
    handleRest() {
      this.form = this.$options.data().form
      this.handleQuery()
    },
    handleConfirmReceipt(row, field) {
      this.$confirm(this.$t('tips.ConfirmReceive'), this.$t('topic.Confirmreceipt'), {
        type: 'warning',
        closeOnClickModal: false,
        beforeClose: async(action, instance, done) => {
          if (action === 'confirm') {
            instance.confirmButtonLoading = instance.cancelButtonLoading = true
            const { msg } = await confirmReceipt({
              [field]: row.id
            }).finally(() => {
              instance.confirmButtonLoading = instance.cancelButtonLoading = false
            })
            this.$message.success(msg)
            await this.handleQuery()
          }
          done()
        }
      })
    },
    handleExport() {
      const {
        operatorType, operatorName, trackingId, rmaId, platForm,
        siteCode, orderCode, sku, returnDetailType, returnType, returnStatus
      } = this.statusParams
      const url = process.env.VUE_APP_OMS_API + 'oms/returns/exportReturnInfos?' + qs.stringify({
        operatorType, operatorName, trackingId, rmaId, platForm,
        siteCode, orderCode, sku, returnDetailType, returnType, returnStatus
      }, {
        arrayFormat: 'comma'
      })
      window.open(url, '_blank')
    },
    handleScroll(e) {
      // 判断方向
      if (e.deltaY > 0) {
        this.$refs.returnStatusScrollbar.wrap.scrollLeft += 15
      } else {
        this.$refs.returnStatusScrollbar.wrap.scrollLeft -= 15
      }
      this.$refs.returnStatusScrollbar.handleScroll()
    },
    handlePagerUpdate(val) {
      this.pager = val
      this.handleQuery()
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
